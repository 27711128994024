<template>
  <b10-base>
    <b10-toolbar
      title="Sincronización"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content
      :padding="3"
    >
      <v-form
        v-model="formValido"
        lazy-validation
      >
        <v-text-field
          v-model="ultimaDescargaGlobalesFormatted"
          label="Última descarga de datos globales (artículos, tarifas, etc.)"
          :single-line="false"
          readonly
        />
      </v-form>
      <v-btn
        block
        color="primary"
        class="mb-3"
        @click.stop="clickDescargarDatosGlobales"
      >
        <v-icon
          left
        >
          {{ $vuetify.icons.values.download }}
        </v-icon>
        Descargar datos globales
      </v-btn>
      <v-card
        v-if="partesDescargados.length > 0"
      >
        <v-card-title
          class="title primary white--text"
        >
          Partes de trabajo descargados
        </v-card-title>
        <v-card-text>
          <v-list>
            <template
              v-for="(item, index) in partesDescargados"
            >
              <v-list-item
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap"
                  >
                    Nº{{ item.idparte_trabajo }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-wrap"
                  >
                    {{ item.tecnicos }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import { downloadTablasGlobales } from '@/sync/download'
import { sync } from 'vuex-pathify'
import { currentDateTime, toDateTime } from '@/utils/date'
import Data from './SincronizacionData'

export default {
  mixins: [basePageMixin],
  data () {
    return {
      toolbarOptions: {
        cambiosPendientes: {
          title: 'Cambios pendientes de subir',
          visible: true,
        },
        sincronizacionesLocales: {
          title: 'Sincronizaciones locales',
          visible: true,
        },
        sincronizacionesRemotas: {
          title: 'Sincronizaciones remotas',
          visible: true,
        },
      },
      formValido: false,
      partesDescargados: [],
    }
  },
  computed: {
    usuarioUltimaDescargaGlobales: sync('usuario/ultimaDescargaGlobales'),
    ultimaDescargaGlobalesFormatted () {
      if (this.usuarioUltimaDescargaGlobales) {
        return this.$options.filters.shortDateTime(toDateTime(this.usuarioUltimaDescargaGlobales))
      } else {
        return 'Nunca'
      }
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.partesDescargados = await Data.selectPartes(this)
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.sincronizacionesLocales) {
        this.$appRouter.push({ name: 'offline__sync-upload-list' })
      } else if (option === this.toolbarOptions.cambiosPendientes) {
        this.$appRouter.push({ name: 'offline__sync-list' })
      } else if (option === this.toolbarOptions.sincronizacionesRemotas) {
        this.$appRouter.push({ name: 'sincronizacion__sync-upload-list' })
      }
    },
    async clickDescargarDatosGlobales () {
      await downloadTablasGlobales(this)
      this.usuarioUltimaDescargaGlobales = currentDateTime()
      this.$alert.showSnackbarSuccess('Datos globales descargados')
    },
  }
}
</script>
