import _ from '@/utils/lodash'

export default {
  async _addSubqueriesPartes (Vue, rows) {
    for (let row of rows) {
      const tables = Vue.$offline.db.tables
      const tecnicosAsignados = await Vue.$offline.db
        .select()
        .from(tables.parte_trabajo_tecnico)
        .innerJoin(
          tables.tecnico,
          tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
        )
        .innerJoin(
          tables.empleado,
          tables.tecnico.idempleado.eq(tables.empleado.idempleado)
        )
        .where(
          Vue.$offline.db.op.and(
            tables.parte_trabajo_tecnico.idparte_trabajo.eq(row.idparte_trabajo)
          )
        )
        .exec()
      row.tecnicos = (_.map(tecnicosAsignados, 'empleado.nombre_y_apellido')).join(' / ')
    }
    return rows
  },
  async selectPartes (Vue) {
    const tables = Vue.$offline.db.tables
    const query = Vue.$offline.db
      .select(
        tables.parte_trabajo.idparte_trabajo
      )
      .from(tables.parte_trabajo)
    const rows = await query.exec()
    return this._addSubqueriesPartes(Vue, rows)
  }
}